import { Box, Image, Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"
import styled, { keyframes } from "styled-components"

import { getPlaceholderData } from "areas/collection2/products/product-image"

const shimmer = keyframes`
  from {
    background-position: -256px 0;
  }
  to {
    background-position: 256px 0;
  }
`

const StyledImage = styled(Image)`
  &.lazyloading {
    animation: ${shimmer} 1.5s forwards infinite;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    overflow: hidden;
  }
`

const ProductName = styled(Typography).attrs({
  fontSize: "16px",
  mb: 1,
  px: "4px",
})``

const CTA = styled(Typography).attrs({
  fontSize: "16px",
  fontWeight: "700",
})`
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`

const ColumnProduct = ({ index, lazyload, linkClickData, product }) => {
  const productLook =
    product.looks[product.default_look] ||
    product.looks[Object.keys(product.looks)[0]]

  if (!productLook) return null

  const placeholder = getPlaceholderData(product.product_type)

  return (
    <Box
      as={Link}
      data-lc={linkClickData}
      display="block"
      height="100%"
      py={2}
      to={productLook.product_url}
    >
      <StyledImage
        alt={product.name}
        fallback={require(`images/collections/placeholder_masks/${
          placeholder[0]
        }_${(index % placeholder[1]) + 1}.svg`)}
        lazyload={lazyload}
        ratio="1 / 1"
        srcSet={productLook.images[0]}
      />
      <ProductName>{product.name}</ProductName>
      <CTA>Shop Now</CTA>
    </Box>
  )
}

ColumnProduct.propTypes = {
  index: PropTypes.number,
  lazyload: PropTypes.bool,
  linkClickData: PropTypes.string,
  product: PropTypes.object,
}

export default ColumnProduct
